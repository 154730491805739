<template>
  <main class="name">
    <router-view/>
  </main>
</template>

<script>


export default {
  name: 'App',
  components: {}
}
</script>

<style lang="scss">
</style>
