// const API = 'https://editor.swagger.io/'

export default {
  actions: {
    // gifts(ctx, data) {
    //   fetch(`${API}/gifts`,{
    //     method: 'POST',
    //     headers: {
    //       'content-type': 'application/json'
    //     },
    //     body: JSON.stringify({data})
    //   }).then((res) => {
    //     console.log(res)
    //   }) .catch((err) => {
    //     console.log(err)
    //   })

    //   console.log(ctx)
    // },

    // redeem(ctx, data) {
    //   fetch(`${API}/gifts/redeem`,{
    //     method: 'POST',
    //     headers: {
    //       'content-type': 'application/json'
    //     },
    //     body: JSON.stringify({data})
    //   }).then((res) => {
    //     console.log(res)
    //   }) .catch((err) => {
    //     console.log(err)
    //   })

    //   console.log(ctx)
    // },

    // status(ctx, data) {
    //   fetch(`${API}/gifts/{nft_id}/status`,{
    //     method: 'GET',
    //     headers: {
    //       'content-type': 'application/json'
    //     },
    //     body: JSON.stringify({data})
    //   }).then((res) => {
    //     console.log(res)
    //   }) .catch((err) => {
    //     console.log(err)
    //   })

    //   console.log(ctx)
    // },
  },
  mutations: {
  },
  state: {
  },
  getters: {
  }
}